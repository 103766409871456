@import "../../../Variables.scss";

.main-details {
  display: flex;
  gap: 30px;
  .details-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .download-btn {
      border: 1px solid $qualitas-grey;
      height: 35px;
      font-size: 13px;
      text-align: start;
      background-color: transparent;
      &:hover {
        cursor: pointer;
      }
      p {
        margin: 0px;
      }
    }  
    .funds-table, .investor-table {
      font-family: $qualitas-font-text;
      width: calc(100vw - 120px - 30px - 250px);
      border-collapse: collapse;
      margin-bottom: 2px;
      .ant-select{
        .ant-select-selection-item{
          font-size: 12px !important;
          font-weight: 600; 
          color: $qualitas-dark-grey !important;
        }
        width: 110px;
      }
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector, .ant-select-focused .ant-select-selector, .ant-picker:hover, .ant-picker-focused{
        border: none;
      }  
      tr {
        &:hover {
          background-color: #f0f0f07e;
          .grey-background {
            background-color: #cacacab0;
          }
        }
        border-bottom: 1px solid $qualitas-grey;
      }
      tbody tr td:first-child {
        text-align: start;
        padding-left: 10px;
      }
      .border-bottom{
        border-bottom: 1px solid black;
      }
      th {
        padding: 0px 12px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.5px;
        color: $qualitas-dark-grey;
        font-size: 11px;
        height: 35px;
      }
      td {
        text-align: center;
        font-size: 13px;
        height: 40px;
        span {
          color: var(--dynamic-color);
        }        
        .row-note{
          color: $qualitas-grey;
          margin-left: 5px;
          font-weight: normal;
        }
        a {
          color: $qualitas-black;
          cursor: pointer;
          font-weight: bold;
        }
      }
      .bold{
        font-weight: bold;
      }
      .grey-background {
        background-color: $qualitas-light-grey;
        font-weight: bold;
      }
      thead > tr {
        border-bottom: 1px solid black;
        &:hover {
          background-color: transparent;
          .grey-background {
            background-color: $qualitas-light-grey;
          }
        }
      }
      .color-background{
        background-color: var(--dynamic-color);
        color: var(--dynamic-text-color);
        font-weight: bold; 
        &:hover{
            background-color: var(--dynamic-secondary-color);
        }
        .row-note{
          margin-left: 5px;
          font-weight: normal;
          color: var(--dynamic-text-color);
        }
      }
    }
	.valuation-disclaimer{
		font-size: 12px;
		color: $qualitas-dark-grey;
	}
    .funds-table{
      margin-bottom: 5px;
    }
    .notes{
      display: flex;
      gap: 15px;
      margin-bottom: 25px;
      p{
        margin: 0;
        font-size: 13px;
        span{
          font-weight: bold;
          margin-right: 5px
        }
      }
    }
    .investor-table-vehicle-row{
      background-color: $qualitas-light-grey;
      font-weight: bold;
      &:hover{
        background-color: $qualitas-light-grey;
        cursor: pointer;
      }
      td span {
        color: $qualitas-black;
        margin: 0;
        margin-left: 5px;
      }

      &:last-of-type{
        background-color: $qualitas-grey;
        &:hover{
          background-color: $qualitas-grey;
          cursor:default;
        }
      }
    }
    .investor-table-item-row{
      td:first-child{
        padding-left: 30px !important;
      }
      td .danger span{
        color: red;
      }
      td  span{
        color: $qualitas-black;
      }
    }
    .hidden-row{
      display: none;
    }
  .investor-details{
    width: calc(100vw - 120px - 30px - 250px);
    min-height: calc(100vh - 120px  - 25px);
    margin-bottom: 25px;

    .ant-spin-text{
      margin-top: 20px;
      color: var(--dynamic-color)-dark;
      font-weight: bold;
    }
    .investors-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .investor-btns-container{
      width: 100%;
    }
    .mode-btn {
        border: none;
        height: 35px;
        width: 50%;
        font-size: 11px;
        background-color: $qualitas-light-grey;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        &:hover {
          cursor: pointer;
        }
        p {
          margin: 0px;
        }
      }  
    .selected-mode{
        background-color: $qualitas-grey;
        font-weight: 600;
    }
    .home-table{
      width: 100%;
    }
  }
}

.funds-sidebar {
  width: 250px;
  background-color: $qualitas-light-grey;
  min-height: calc(100vh - 120px);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  .search-by-container{
    .search-by-btn{
      width: 125px;
      border: none;
      border-bottom: 1px solid $qualitas-grey;
      height: 40px;
      cursor: pointer;
    }
    .selected-btn{
      background-color: $qualitas-grey;
      font-weight: 600;
    }
  }
  h3 {
    padding-left: 20px;
    font-family: $qualitas-font-title;
    font-size: 17px;
  }

  .selectedPeip {
    font-weight: 800;
  }

  .funds-list {
    padding: 10px 0px 20px 0px;
    box-sizing: border-box;
    .funds-list-title {
      font-family: $qualitas-font-text;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid $qualitas-dark-grey;
      margin: 0px;
    }
    display: flex;
    flex-direction: column;
    gap: 18px;
    button {
      border: none;
      text-align: start;
      color: $qualitas-dark-grey;
      background-color: transparent;
      &:hover {
        cursor: pointer;
      }
      p {
        margin: 0px;
      }
    }
  }

  .selectedVehicle {
    font-weight: 800;
  }

  .collapse-funds {
    border-top: 1px solid $qualitas-grey;
    border-radius: 0px;
    .ant-collapse-header {
      height: 50px;
      padding: 0px 20px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $qualitas-grey; 
      border-radius: 0px !important;
      //margin: 10px 0px;
      background-color: transparent;
      span {
        font-size: 13px;
        font-family: $qualitas-font-text;
      }
    }
    .ant-collapse-content-box {
      padding: 0px 0px 0px 20px !important;
    }
  }
  .option-list:first-child {
    margin-bottom: 50px;
  }
  .search-btn input {
    padding: 0px 0px 4px 10px;
    &::placeholder {
      font-size: 11px;
      letter-spacing: 1.3px;
      color: $qualitas-grey;
    }
  }

  .investor-item {
    border-radius: 0px;
    padding: 0px 10px;
    border-bottom: 1px solid $qualitas-grey;
    height: 52px;
    button{
      display: flex;
      height: 50px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border: none;
      text-align: start;
      p{
        font-size: 13px;
        margin: 0px;
      }
      font-size: 13px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.ant-select-item-option-selected{
  background-color: $qualitas-light-grey !important; 
}

.investor-documents{
  min-height: 210px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  h3{
    font-size: 15px;
    margin: 0;
    font-family: $qualitas-font-title;
  }
  p:first-of-type{
      font-weight: bold;
  }
  .ant-select{
      width: 230px;
  }
  .generate-item{
    height: 35px;
    p{
      margin: 0;
      min-width: 100px;
      font-family: $qualitas-font-text;
      font-weight: normal;
      font-size: 13px;
    }
    display: flex;
    align-items: center;
    gap: 15px;
    &:last-of-type{
      border-bottom: 1px solid $qualitas-grey;
      padding-bottom: 25px;
    }
  }
  .open-document-button{
    align-self: center;
    font-size: 13px;
    width: 170px;
    border-radius: 0px;
    &:hover{
      background-color: var(--dynamic-secondary-color);
      color: $qualitas-black;
      border-color: $qualitas-grey;
    }
  }

  .ant-select-selector, .ant-picker{
    border: 1px solid $qualitas-grey;
    width: 220px ;
    font-size: 13px;
    border-radius: 0px;
    .ant-picker-input > input{
      font-size: 13px;
    }
  }
  .ant-select-item-option-content{
    font-size: 13px;
  }
}

.ant-modal .ant-modal-content{
  border-radius: 0px;
}

.generate-btn, .view-documents-btn{
  background-color: transparent;
  border: 1px solid $qualitas-dark-grey;
  padding: 5px;
  &:hover{
    background-color: $qualitas-light-grey;
    cursor: pointer;
  }
}

.view-documents-btn{
  border: none;
  &:hover{
    background-color: transparent;
  }
}

.cashflow-chart{
  margin: 30px 0px
}

.fund-charts{
  .fund-chart{
    position: relative;
    width: 100%;
    &:first-of-type{
      padding-right: 30px;
      border-right: 1px solid $qualitas-grey;
    }
    .chart-title{
      display: flex;
      justify-content: space-between;
      button{
        font-size: 16px;
      }
    }    
  }
  box-sizing: border-box;
  width: calc(100vw - 120px - 30px - 250px);
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  .no-data-found{
    position: absolute;
    width: 100px;
    top: 50%;
    left: 40%;
    text-align: center;
    font-size: 14px;
  }
}

.skeleton-container{
  padding: 0px 20px;
}

.netasset-chart-container{
  height: 340px;
}

.asset-value-chart{
  margin-bottom: 25px;
  position: relative;
  .no-data-found{
    position: absolute;
    width: 150px;
    top: 50%;
    left: 42%;
    text-align: center;
    font-size: 14px;
  }
}
