@import "../../Variables.scss";

.under-maintenance {
  width: 100vw;
  background-color: $qualitas-black;
  height: 100vh;
  box-sizing: border-box;

  .under-maintanance-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
    gap: 15px;
    align-items: center;
    justify-content: center;
    h2 {
      color: $qualitas-white;
      font-family: $qualitas-font-title;
      text-align: center;
      width: 350px;
      margin: 0;
      font-size: 32px;
    }
    p{
        color: $qualitas-white;
        text-align: center;
        padding: 0 20px;
    }
    a{
      text-decoration: underline;
      color: $qualitas-white;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .under-maintanance-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px 0 50px;
    height: 80px;

    .logout-btn{
        border: none;
        background-color: transparent;
        color: $qualitas-white;

        &:hover{
            cursor: pointer;
        }
    }
  }
}
