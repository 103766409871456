@import '../../../Variables.scss';

.home{
    padding-left: 30px;
    min-height: calc(100vh - 230px);
    .skeleton-loading{
        width: calc(100vw - 120px - 30px);
    }
    .search-input{
        ::placeholder{
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.1px;
        }
    }
    .home-table{
        font-family: $qualitas-font-text;
        width: calc(100vw - 120px - 30px);
		border-collapse: collapse;
        margin-bottom: 30px;
        tr{
            &:hover{
                background-color:  #f0f0f07e;
                .grey-background{
                    background-color: #cacacab0
                }
            }
            border-bottom: 1px solid $qualitas-grey
        }
        th{
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.5px;
            color: $qualitas-dark-grey;
            font-size: 11px;
            height: 35px;
        }
        td{
            text-align: center;
            font-size: 13.5px;
            height: 40px;
            span{
                color: var(--dynamic-color);
            }
            a{
                color: $qualitas-black;
                cursor: pointer;
                font-weight: bold;
            }
        }
        .currency-tag{
            span{
                background-color: $qualitas-light-grey;
                padding: 8px;
                color: $qualitas-black;
                border-radius: 5px;
                font-size: 13px;
                font-weight: bold;
            }
        }
        .investment-name{
            text-align: start;
            padding-left: 50px;
            span{
                color: red;
            }
        }
        .program-name{
            text-align: start;
            font-weight: bold;
            height: 35px;
            font-size: 14px;
            font-family: $qualitas-font-title;
        }
        .report-btn{
			button{
				font-size: 13px;
				border: none;
				background-color: transparent;
				font-weight: bold;
				cursor: pointer;
			}
        }

        .grey-background{
            background-color: $qualitas-light-grey;
            font-weight: bold;
        }
        thead > tr{
            border-bottom: 1px solid black;
            &:hover{
                background-color: transparent;
                .grey-background{
                    background-color: $qualitas-light-grey;
                }
            }
        }    
    }
    .bell-btn{
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
    .home-header{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .no-data-found{
        position: absolute;
        top: 50%;
        left: 45%;
        text-align: center;
        font-size: 16px;
    }
	.search-exceded{
		text-align: center;
		font-style: italic;
	}
	.pagination-container{
		margin: 20px 0;
		display: flex;
		justify-content: center;
		width: 100%;
		.pagination-component{
			width: 280px;
			font-size: 13px;
			text-align: center;
			p{
				margin: 0px;
				letter-spacing: 1px;
			}
			button{
				width: 280px;
				height: 40px;
				border: 1px solid $qualitas-black;
				border-radius: none;
				background-color: transparent;
				text-transform: uppercase;
				letter-spacing: 2px;
				transition: 0.3s;
				&:hover{
					background-color: $qualitas-black;
					color: white;
					transition: 0.3s;
					cursor: pointer;
				}
				&:disabled{
					background-color: $qualitas-light-grey;
					border-color: $qualitas-light-grey;
					color: $qualitas-dark-grey;
					cursor: not-allowed;

				}
			}
		}
	}
}

.ant-tooltip-inner{
    font-size: 13px;
    width: 170px;
}

.flow-chart{
    height: 340px;
    width: 100%;
    position: relative;
}

.home-footer{
    margin-bottom: 25px;
    display: grid;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    h4{
        margin-top: 0px;
    }
}
.recent-activity{
    width: 100%;
    p{
        margin: 0;
    }
    h3{
        margin: 0 0 15px 0;
    }
    .recent-activity-item{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px 20px;
        background-color: $qualitas-light-grey;
        &:nth-child(even){
            background-color: transparent;
        }
        .recent-activity-item-container{
            width: 100%;
        }
        .recent-activity-header{
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px dotted $qualitas-grey;
            padding: 8px 0;
            p{
                text-transform: uppercase;
                font-size: 13px;
                color: $qualitas-black;
                font-weight: 600;
            }
            a{
                color: $qualitas-black;
                font-size: 11px;
                text-decoration: underline;
            }
        }
        .recent-activity-item-title{
            font-size: 13px;
        }
        .recent-activity-item-date{
            font-size: 13px;
        }
        .recent-activity-item-no-data{
            font-size: 13px;
            color: $qualitas-black;
            font-weight: 600;
        }
        .recent-activity-item-no-data{
            display: flex;
            width: 100%;
            font-weight: normal;
            color: $qualitas-dark-grey;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
        .recent-activity-item-data{
            border: none;
            background-color: transparent;
            padding: 7px;
            display: flex;
            justify-content: space-between;
            color: $qualitas-black;
            width: 100%;
            &:hover{
                font-weight: bold;
                cursor: pointer
            }

        }
    }
}