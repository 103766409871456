@import "../../../Variables.scss";

.reporting {
  width: calc(100vw - 90px - 250px);
}

.investments-sidebar {
    width: 250px !important;
    background-color: $qualitas-light-grey;
    min-height: calc(100vh - 120px);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  
    h3 {
      padding-left: 20px;
      font-family: $qualitas-font-title;
      font-size: 17px;
    }
    .selectedOption{
      font-weight: bold;
    }
}

.main-investments{
  display: flex;
  width: calc(100vw - 120px);
}

.investment-item {
  border-radius: 0px;
  padding: 0px 10px;
  border-bottom: 1px solid $qualitas-grey;
  height: 52px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    //background-color: $qualitas-grey;
    transition: 0.3s;

  }
  button{
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: none;
    text-align: start;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      //background-color: $qualitas-grey;
      transition: 0.3s;

    }  
    p{
      font-size: 13px;
      margin: 0px;
    }
    font-size: 13px;
  }
}
.investments-sidebar-plan .investment-item:first-of-type,
.investments-sidebar-analyze .investment-item:first-of-type
{
  border-top: 1px solid $qualitas-grey;
}

.investments-sidebar-plan{
  margin-bottom: 50px;
}

.no-permissions{
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 15px;
  color: $qualitas-dark-grey;
  font-weight: bold;
}