@import "../node_modules/@qualitas/auth/dist/Auth.scss";
@import "../node_modules/@qualitas/files-viewer/dist/FilesViewer.scss";
@import "../node_modules/@qualitas/reporting/dist/AnalysisAndReporting.scss";
@import "../node_modules/@qualitas/navbar/dist/Navbar.scss";
@import "../node_modules/@qualitas/documentviewer/dist/DocumentViewer.scss";
@import "./Variables.scss";

html,
body,
#app {
  min-height: 100vh;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.ant-layout {
  min-height: 100vh;
  background-color: transparent;
}

.investors-table-header {
  margin-bottom: 16px;
}

.title-tab {
  color: #001435;
  font-weight: bold;
  font-size: 15px;
}

.section-title {
  padding: 10px 0;
  text-align: left;
  font-weight: bold;
}

.aside-menu-container {
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  padding: 10px;
}

.content-wrapper {
  height: 100%;
}

.reporting {
  min-height: 90vh;

  iframe {
    width: 120%;
  }
}

.layout {
  min-height: calc(100vh - 80px - 40px);
  &-sider {
    .ant-layout-sider-children {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.layout-sider {
  background-color: $qualitas-grey !important;
  padding: 10px 30px;
}

.language-selector {
  display: flex;
  .selectedLanguage {
    color: $qualitas-white;
  }
  button {
    background-color: transparent;
    color: $qualitas-dark-grey;
    width: 50px;
    border: none;
    &:first-of-type {
      border-right: 1px solid $qualitas-dark-grey;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.noInvestorsAsociated {
  font-size: 15px;
  color: $qualitas-dark-grey;
  text-align: center;
  margin: 50px 15px;
}

.investor-portal-app {
  min-height: calc(100vh);
}

input[type="checkbox"],
input[type="radio"] {
  width: 15px;
  height: 15px;
  accent-color: var(--dynamic-color);
}

.empty-data {
  height: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.empty-data-grey {
  height: 250px;
  background-color: $qualitas-light-grey;
  p {
    margin: 0;
    font-size: 13px;
    width: 250px;
    text-align: center;
    color: $qualitas-dark-grey;
  }
}
.empty-data-chart{
  height: 300px;
  background-image: url('https://qualitasfunds.blob.core.windows.net/assets/image-gallery/empty-chart.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  p{
    width: 200px;
    text-align: center;
    font-weight: bold;
    color: $qualitas-dark-grey;
  }
}

.welcome-page {
  background-color: $qualitas-black;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  img {
    opacity: 0;
    width: 160px;
    animation: fadeOut 3s forwards;
  }
  h2,
  span {
    animation: fadeOut 3s forwards;
    font-family: Lato, sans-serif;
    font-size: 36px;
    font-weight: 300;
    color: $qualitas-white;
  }
  span {
    font-weight: 500;
  }
  .lines-moving {
    z-index: 10;
    transform: translate(-0%, -0%);
    animation: move 1.5s 1.5s forwards;
  }

  @keyframes move {
    to {
      transform: translate(300px, 0%);
      opacity: 10%;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 0%;
      background-color: $qualitas-black;
    }
    80% {
      opacity: 100%;
    }
    100% {
      opacity: 0%;
    }
  }
}

