@import "../../../Variables.scss";

.media-container{
    min-height: calc(100vh - 120px);
    width: calc(100vw - 90px);

    h2{
        font-family: $qualitas-font-title;
        font-size: 24px;
        margin: 0px;
        padding: 30px 30px 15px 30px;
        width: 100%;
        border-bottom: 1px solid black;
      }
    .qualitas-tv, .news, .newsletter{
        padding: 15px 30px;
        h3{
            margin: 0px 0px 20px 0px;
            font-size: 26px;
            font-family: $qualitas-font-text;
            font-weight: 300;
        }
    }
    .videos-container, .news-container{
        display: grid;
        grid-template-columns: repeat(auto-fit, 290px);
        column-gap: 20px;
        row-gap: 20px;
        .video-item{
            width: 290px;
            margin-bottom: 10px;
            p{
                color: $qualitas-white;
                margin: 10px 0 0 0;
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing: 1px;
            }
        }
        .news-item, .newsletter-item{
            height: 240px;
            border-bottom: 1px solid $qualitas-grey;
            margin: 0px 25px 0px 0px;
            .news-category{
                text-transform: uppercase;
                color: $qualitas-dark-grey;
                font-size: 13px;
                letter-spacing: 1px;
            }
            .news-title{
                font-family: $qualitas-font-title;
                font-size: 20px;
                font-weight: 600;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .news-description{
                margin-bottom: 6px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                img{
                    display: none
                }
                p{
                    margin: 0;
                }
            }
            .readmode-btn{
                color: $qualitas-black;
                text-decoration: underline;
            }
        }
        .newsletter-item{
            border-bottom: none;
            height: 180px;

        }
    }
    .news{
        background-color: $qualitas-light-grey;
        padding-bottom: 35px;
        display: flex;
        flex-direction: column;
        h3{
            margin-top: 15px;
        }
        .loadmore-btn{
            align-self: center;
            border: 1px solid $qualitas-dark-grey;
            border-radius: 0px;
            padding: 5px 15px;
            margin-top: 25px;
            &:hover{
                cursor: pointer;
            }
        }
    }

    .qualitas-tv{
        background-color: $qualitas-black;
        h3{
            color: $qualitas-white;
        }
    }
    .newsletter-link{
        background-color: $qualitas-light-grey;
        height: 150px;
        width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px;
        text-decoration: none;
        color: $qualitas-black;
        p:first-child{
            font-size: 14px;
            font-weight: 600;
            margin: 0px;
        }
        p:last-child{
            line-height: 50px;
            font-size: 70px;
            margin: 0px;
            align-self: flex-end;
            font-weight: 200;
        }
    }
}