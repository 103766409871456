@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;700;800&family=Merriweather:wght@300;400&display=swap');
@font-face {
    font-family: 'Palatino';
    src: url('./Assets/fonts/Palatino.ttf') format('opentype');
}

$qualitas-white: #FFFFFF;
$qualitas-black: #000000;
$qualitas-grey: #c9c9c9;
$qualitas-dark-grey: #6B6B6B;
$qualitas-light-grey: #f0f0f0;
$qualitas-green: #B3D615;
$qualitas-font-text: 'Lato', sans-serif;
$qualitas-font-title: 'Palatino', serif;

//Variants for graphics

$qualitas-graphic1: #696969;
$qualitas-graphic2: #a0a0a0;
$qualitas-graphic3: #aaaaaa;
