@import '../../Variables.scss';

.navigation-menu{
    width: 90px;
    background-color: $qualitas-light-grey;
    min-height: calc(100vh - 120px);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 10;
    .navigation-menu_item{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        text-align: center;
        color: $qualitas-black;
        border: none;
        background-color: $qualitas-light-grey;
        font-size: 30px;
        position: relative;
        transition: 0.3s;
        span{
            width: 32px;
            transition: 0.3s;
        }
        &:hover{
            background-color: $qualitas-grey;
            cursor: pointer;
            transition: 0.3s;
        }
    }
    .selected{
        background-color: var(--dynamic-color);
        transition: 0.3s;
        &:hover{
            background-color: var(--dynamic-color);
            transition: 0.3s;
            cursor: pointer;
        }
    }
    .selected:hover .navigation-menu-item-hover{
        color: var(--dynamic-text-color);
    }

    .navigation-menu-item-container{
        display: flex;
        position: relative;
        &:hover .navigation-menu-item-hover{
            opacity: 1;
            transition: 0.3s;
            cursor: pointer;
        }
        &:hover .navigation-menu-icon{
            opacity: 0;
            transition: 0.3s;
        }
    }


    .navigation-menu-item-hover{
        position: absolute;
        height: 90px;
        font-size: 13px;
        font-family: $qualitas-font-title;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 90px;
        padding: 5px;
        transition: 0.3s;
        opacity: 0;
        p{
            border-bottom: 2px solid var(--dynamic-color);
            padding-bottom: 3px;
        }
    }  
}
.investor-portal-viewport{
    display: flex;
    //gap: 30px;
    box-sizing: border-box;
    h1{
        font-family: $qualitas-font-title;
        font-size: 22px;
        margin: 12px 0px 0px 0px;
    }
    h2{
        font-family: $qualitas-font-title;
        font-size: 18px;
        span{
            font-family: $qualitas-font-title;
            color: $qualitas-dark-grey;
            font-size: 18px;
        }
    }
}

.cls-1{
    fill: #1d1d1b;
    stroke-width: 0px;
}

.selected .cls-1{
    fill: var(--dynamic-text-color);
}

